
export interface OidcConfiguration {
  client_id: string;
  client_secret: string;
  redirect_uri: string;
  silent_redirect_uri: string;
  scope: string;
  authority: string;
}

// const oidcConfiguration: OidcConfiguration = {
//   client_id: "98534780-1548-4fb0-b09c-5d3d1ee2e0ad",
//   client_secret: "secret",
//   redirect_uri: "https://ca-gmscloud-webapps-nus.greenpond-35a6bf9a.northcentralus.azurecontainerapps.io/callback", 
//   silent_redirect_uri: "https://ca-gmscloud-webapps-nus.greenpond-35a6bf9a.northcentralus.azurecontainerapps.io/silent-callback",
//   scope: "openid",
//   authority: "https://demogms.b2clogin.com/demogms.onmicrosoft.com/B2C_1_signin_only_nomfa"
// };

const oidcConfiguration: OidcConfiguration = {
  client_id: "98534780-1548-4fb0-b09c-5d3d1ee2e0ad",
  client_secret: "secret",
  redirect_uri: "https://test-providerweb.gracefulmanagement.com/callback", 
  silent_redirect_uri: "https://test-providerweb.gracefulmanagement.com/silent-callback",
  scope: "openid",
  authority: "https://demogms.b2clogin.com/demogms.onmicrosoft.com/B2C_1_signin_only_nomfa"
};

// const oidcConfiguration: OidcConfiguration = {
//   client_id: "98534780-1548-4fb0-b09c-5d3d1ee2e0ad",
//   client_secret: "secret",
//   redirect_uri: "http://localhost:3005/callback",
//   silent_redirect_uri: "http://localhost:3005/silent-callback",
//   scope: "openid",
//   authority: "https://demogms.b2clogin.com/demogms.onmicrosoft.com/B2C_1_signin_only_nomfa"
// };

export const ConfigUrl = {
  baseURL: "https://ca-gmscloud-provider-api-dev-nus.greenpond-35a6bf9a.northcentralus.azurecontainerapps.io/gms-provider-admin/api/v1",
  //  baseURL: "http://localhost:8085/gms-provider-admin/api/v1",
};

export default oidcConfiguration;
