import { executeDeleteData, executeGetData, executePostData, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";


export const getTrades = async(accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/trades`;
  console.log(url)
  const response=  await executeGetData(url, accessToken);
  return response;

}

export const addTrade = async(payload:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/trades`;
  
  const response= await executePostData(url, payload, accessToken);
  return response;

}

export const updateTrade = async(id:any, payload:any, accessToken:any) =>
  {
    const url = `${ConfigUrl.baseURL}/trades/${id}`;
    const response=  await executePutData(url, payload, accessToken);
    return response;

  }

export const getTradeNames = async(accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/trades/getalltrades`;
  console.log(url)
  const response=  await executeGetData(url, accessToken);
  return response;

}

export const deleteTrade = async(tradeId:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/trades/${tradeId}`;
  const response=  await executeDeleteData(url, accessToken);
  return response;

}
export const getTradeDetails = async ( tradeName,accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/trades/tradeName/${tradeName}`;
    const response= await executeGetData(url,accessToken);
    console.log(response)
    return response;
  
  }
