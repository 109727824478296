
import React from 'react'
import Avatars from './Avatar Template/Avatar'
import Buttons from './ButtonTemplates/Button'
import Progress from './ProgressBar Template/Progress'
import SampleView from './SampleTemplates/SampleView/SampleView'
import Steppers from './Stepper Template/Steppers'
import Timelines from './Timeline Template/Timeline'
import Calender from './calendar Template/Calendar'
import Sample from './sample'
import { BrowserRouter as Router, Routes as ReactRoutes, Route } from 'react-router-dom';
import Home from './Home'
import CommonDashboard from '../CommonDashboard'
import TenantProvisioning from '../components/tenant-webapp/TenantProvisioningPage'
import TenantUserSetup from '../components/userSetUp/TenantUserSetup'
import Tenant from '../components/tenant-webapp/tenantList/Tenant'
import TradeQuestions from '../components/questionBuilder/TradeQuestions'
import Questions from '../components/questionBuilder/components/Questions'
import AddCategory from '../components/AddCategory'
import CategoryMasterList from '../components/CategoryMasterList'


const PageRoutes = () => {
  return (
    <div>

      <ReactRoutes>

        <Route path='/' element={<Home />} />
    
        <Route path="/calender" element={<Calender />} />
      
        <Route path="/sample" element={<Sample />} />
        
        <Route path="/sampleview" element={<SampleView />} />

        <Route path="/button" element={<Buttons />} />

        <Route path="/progress" element={<Progress />} />

        <Route path="/timeline" element={<Timelines />} />

        <Route path="/avatar" element={<Avatars />} />

        <Route path="/stepper" element={<Steppers />} />

        <Route path="/calender" element={<Calender />} />  
        <Route path="/commondashboard" element={<CommonDashboard />} />

        <Route path="/tenantuser" element={<TenantUserSetup />} />
    
        <Route path="/tenant" element={<Tenant />} />
        <Route path="/tenantprovision" element={<TenantProvisioning />} />

        <Route path="/tradeconfiguration" element={<TradeQuestions />} />
        <Route path="/assettemplate" element={<Questions questionType='asset' tradeTypeName='asset' />} />
        <Route path="/categorymaster" element={<CategoryMasterList  />} />

      </ReactRoutes>

    </div>
  )
}

export default PageRoutes;
