import { executeDeleteData, executeGetData, executePostData, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";

export const getCategoryMaster = async (accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/categoryMaster/getAllCategories`;
    return await executeGetData(url, accessToken);
  };
  
  export const addCategory= async (payload: any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/categoryMaster/createCategory`;
    return await executePostData(url, payload, accessToken);
  };
  