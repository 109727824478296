/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : karthikeyan
* @since       : 0.0.1
* Requirement# :
* Purpose      : Create Bid Wizard Form
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1557           |  10/04/24   | shiyam Kumar  |  Form For Add And Edit Trade
* 1757           |  02/05/24   | Vanitha       |  implememtation for No value has been changed in Add And Edit Trade and Bind the Trade Name
* 1782           | 09-05-2024  | Shiyam Kumar  | MAde Changes For Trade Domain Change
*/


import * as React from 'react';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Checkbox, ListItemText, Divider } from '@mui/material';
import CustomDialog from '../pages/Dialog';
import { addTrade, updateTrade } from '../services/TradeConfigurationServics';



const TradeForm: React.FC<any> = (props) => {

    const modalstyle1 = {
        width: 'sm', // Assuming 'sm' is a predefined width in your theme, otherwise set an appropriate width like '300px' or similar
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
        maxHeight: '70vh', // Set the maximum height for scrolling
        overflowY: 'auto', // Enable vertical scrolling


    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [trade, setTrade] = React.useState<any>();
    const [validated, setValidated] = React.useState<any>(false);
    const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
    const [openAddDialog, setOpenAddDialog] = React.useState(false);
    const [updateSuccess, setUpdateSuccess] = React.useState(false);
    const [updateError, setUpdateError] = React.useState(false);
    const [addSuccess, setAddSuccess] = React.useState(false);
    const [addError, setAddError] = React.useState(false);
    const [noChange, setNoChange] = React.useState(false);
    const [count, setCount] = React.useState(0);
    console.log("count",count)

    const [trades, setTrades] = React.useState(["Roofing", "Siding", "Guttering"]);
    const codes = { "Roofing": "1101", "Siding": "1102", "Guttering": "1103" };
    const [materials, setMaterials] = React.useState(["Asphalt Shingles", "Vinyl Siding", "Aluminum"]);
    const [manufacturers, setManufacturers] = React.useState(["Owens Corning", "GAF", "Certain Teed", "Norandex", "James Hardie", "Smart Side", "ACM", "Berger", "US Aluminum Inc"]);
    const [data, setData] = React.useState<any>({
        name: undefined,
        // manufacturer: [],
        // materialType: undefined,
        description: undefined,
        code: undefined,
        type: undefined

    });
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);


    const handleClose = () => {
        props.function();
        setData({
            name: undefined,
            // manufacturer: [],
            // materialType: undefined,
            description: undefined,
            code: undefined,
            type: undefined
        });
        props.setTradeName(data.name)
        setValidated(false);
    };

    const handleNoChange = (event?: React.SyntheticEvent | Event, reason?: string) => {

        setNoChange(false)

    }

    const tradehandleChange = (event: any) => {
        console.log(event.target.value)
        let costCode = codes[event.target.value];
        console.log(costCode,codes)
        //console.log(event)
        if (event.target.name === "type") {
            Object.assign(data, { type: event.target.value, code: costCode });
            //setData({ ...data, name: event.target.value, code: costCode});
        }
        // if (event.target.name === "manufacturer") {
        //     setData({ ...data, [event.target.name]: event.target.value });
        // }
        else {
            setData((prevData) => ({
                ...prevData,
                [event.target.name]: event.target.value,
                // code: 1101
            }));
        }
    };

    const handleChange = (event: any) => {
        setData((prevData) => ({
            ...prevData,
            [event.target.name]: event.target.value,
            // code:'1101'
        }));
    };
    const validation = () => {
        let validated: boolean = true;
    
        if (!data?.name || data.name === '') {
            validated = false;
        }
    
        if (!data?.type || data.type === '') {
            validated = false;
        }
    
        // if (!data?.description || data.description === '') {
        //     validated = false;
        // }
            
        if (!data?.code || data.code === '') {
            validated = false;
        }
    
    
        console.log(validated);
        return validated;
    }
    const handleAddTrade = async () => {
        //console.log(data)
        // if (data.name !== undefined) {
            setCount(count + 1);
            let validated = validation();
            console.log(validated)
            if(validated){
            setOpenAddDialog(true);
            console.log(data)
            const result = await addTrade(data, accessToken);
            console.log("TradeForm", result);
            if ((result.status === 200 || result.status === 201) && result.data === true) {
                console.log(result)
                // props.setTradeType(data.type)
                // props.setSuccess( result.data);
                setAddSuccess(true);
            } else 
            {
                setAddError(true);
            }
        }
      
        // } else {
        //     setValidated(true);
        // }
    }

    // const handleUpdateTrade = async () => {
    //     setOpenUpdateDialog(true);
    //     const result = await updateTrade(props.selected.id, data, accessToken);
    //     if (result.status === 200 || result.status === 201) {
    //         setUpdateSuccess(true);
    //     } else {
    //         setUpdateError(true);
    //         setNoChange(true);
    //     }
    // }

    const handleUpdateTrade = async () => {
        // if (props.selected.description === data.description || props.selected.name === data.name  ) {
        //     setNoChange(true);
        //     setOpenUpdateDialog(false);
        // } else {
        const result = await updateTrade(props.selected.id, data, accessToken);
        setUpdateSuccess(true);
        setOpenUpdateDialog(true);
        // }
    };

    const closeDialog = () => {
        //closeTemplate();
        handleClose();
        setValidated(false);
        setOpenAddDialog(false);
        setAddSuccess(false);
        setAddError(false);
        setOpenUpdateDialog(false);
        setUpdateSuccess(false);
        setUpdateError(false);
        props.handleShowQuestionTemplate();


    }

    React.useEffect(() => {
        setData({
            name: props.selected?.name,
            // manufacturer: props.selected?.manufacturer === undefined ? [] : props.selected?.manufacturer,
            // materialType: props.selected?.materialType,
            description: props.selected?.description,
            code: props.selected?.code,
            type: props.selected?.type
        });
        //let selectedtrade = trades.filter(data => data.name === props.selected?.name);
        setTrade(props.selected?.type);
    }, []);

    return (
        <><Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalstyle1}>
                <Typography id="modal-modal-title" variant="h4" component="h2" mb={1}>
                    Add New trade
                </Typography>
                <Divider />
                <Grid
                    container
                    sx={{ pt: 4, pl: 0, mt: 1 }}
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                {/* <FormControl sx={{ m: 0, width: '100%' }} size="small" required error={data.name === undefined && validated}> */}
                                    {/* <InputLabel id="demo-select-small-label">Trade Name</InputLabel> */}
                                    {/* <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        // value={data.name === undefined ? trade : data.name}
                                        disabled={props?.selected !== undefined}
                                        value={data.name === undefined ? props?.selected?.name : data.name}
                                        label="Select Trade"
                                        name='name' 
                                        onChange={tradehandleChange}
                                    >
                                        {trades.map((trade: any) => (
                                            <MenuItem value={trade}>
                                                {trade}
                                            </MenuItem>
                                        ))}
                                    </Select> */}
                                    <TextField
                                        id="outlined-multiline-static"
                                        disabled={props?.selected !== undefined}
                                        error={!data?.name&& count !== 0}
                                        value={data.name}
                                        label="Trade Name"
                                        name="name"
                                        onChange={tradehandleChange}
                                        fullWidth
                                        size='small'
                                    />

                                {/* </FormControl> */}
                            </Grid>
                            <Grid item xs={4}>
                                {/* <FormControl sx={{ m: 0, width: '100%' }} size="small" required error={data.type === undefined && validated}>
                                    <InputLabel id="demo-select-small-label">Trade Type</InputLabel> */}
                                    {/* <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        disabled={props?.selected !== undefined}
                                        value={data.materialType === undefined ? props?.selected?.materialType : data.materialType}
                                        label="Select Material"
                                        name='materialType'
                                        onChange={tradehandleChange}
                                    >
                                        {materials.map((trade: any) => (
                                            <MenuItem value={trade}>
                                                {trade}
                                            </MenuItem>
                                        ))}
                                    </Select> */}
                                    {/* <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        disabled={props?.selected !== undefined}
                                        value={data.type === undefined ? props?.selected : trade}
                                        label="Select Trade"
                                        name='type'
                                        onChange={tradehandleChange}
                                    >
                                        {trades.map((trade: any) => (
                                            <MenuItem key={trade.code} value={trade}>
                                                {trade}
                                            </MenuItem>
                                        ))}
                                    </Select> */}

                                    <TextField
                                        id="outlined-multiline-static"
                                        disabled={props?.selected !== undefined}
                                        error={!data?.type && count !== 0}
                                        value={data?.type}
                                        label="Trade Type"
                                        name="type"
                                        onChange={tradehandleChange}
                                        fullWidth
                                        size='small'
                                    />
                                {/* </FormControl> */}
                            </Grid>
                            <Grid item xs={4}>
                            <TextField
                                        id="trade-textfield"
                                        disabled={props?.selected !== undefined}
                                        value={data.code}
                                        label="Industrial Code"
                                        name="code"
                                        onChange={tradehandleChange}
                                        fullWidth
                                        error={!data?.code && count !== 0}
                                        size='small'
                                    />
                                {/* <FormControl sx={{ m: 0, width: '100%' }} size="small" required error={data.manufacturer === undefined && validated}>
                                    <InputLabel id="demo-select-small-label">Select Manufacturer</InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        disabled={props?.selected !== undefined}
                                        value={data.manufacturer === undefined ? props?.selected?.manufacturer : data.manufacturer}
                                        label="Select Manufacturer"
                                        name='manufacturer'
                                        onChange={tradehandleChange}
                                    >
                                        {manufacturers.map((trade: any) => (
                                            <MenuItem value={trade}>
                                                {trade}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl> */}
                                {/* <FormControl sx={{ m: 0, width: '100%' }} size="small" required error={data.manufacturer === undefined && validated}>
                                    <InputLabel id="demo-multiple-checkbox-label">Select Manufacturer</InputLabel>
                                    <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    label="Select Manufacturer"
                                    multiple
                                    value={data.manufacturer}
                                    name='manufacturer'
                                    onChange={tradehandleChange}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                    >
                                    {manufacturers.map((name) => (
                                        <MenuItem key={name} value={name}>
                                        <Checkbox checked={data.manufacturer.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl> */}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        sx={{ pt: 2, pl: 3, mt: 5 }}
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                    >

                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Description"
                                size="small"
                                name='description'
                                fullWidth
                                multiline
                                rows={4}
                                // error={!data?.description && count !== 0}
                                value={data?.description === undefined ? props?.selected?.description : data.description}
                                onChange={handleChange} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid container justifyContent="flex-end">
                        <Box m={1} className="poFooter">
                            <Button
                                sx={{ marginRight: "32px" }}
                                className={`ErrorTonalButton`}
                                variant="contained"
                                size="medium"
                                color="error"
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                            {props.selected === undefined ?
                                <Button
                                    className="button"
                                    variant="contained"
                                    size="medium"
                                    onClick={handleAddTrade}
                                >
                                    Save
                                </Button> :
                                <Button
                                    className="button"
                                    variant="contained"
                                    size="medium"
                                    onClick={handleUpdateTrade}
                                >
                                    Update
                                </Button>
                            }
                        </Box>
                    </Grid>
                </Grid>

            </Box>
        </Modal>
            <CustomDialog
                open={openAddDialog}
                onClose={closeDialog}
                success={addSuccess}
                error={addError}
                Content={addSuccess ? <span>Trade Added Successfully,<br />Create your question builder based on the trade</span> :
                    "Failed To Add"} />
            <CustomDialog
                open={openUpdateDialog}
                onClose={closeDialog}
                success={updateSuccess}
                error={updateError}

                Content={updateSuccess ? "Trade Updated Successfully" : "Failed To Update"} />
            <Dialog open={noChange}>
                <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} />
                        </Typography>
                    </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent>
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>
                            No Value has been Changed
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Ok</Button>
                    </DialogActions>
                </Box>
            </Dialog>


        </>

    );
}

export default TradeForm;