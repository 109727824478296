import React from 'react';
import { Box, FormControl, FormControlLabel, Grid, Checkbox, TextField, Button, IconButton } from '@mui/material';
import { CodeList, CodeListItem } from '../../../interface/SdmInterfaces';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  index: string;
  questions: any[]; // Replace with your specific type
  setQuestions: React.Dispatch<React.SetStateAction<any[]>>; // Replace with your specific type
  codeList: CodeList[];
  setCodeList: React.Dispatch<React.SetStateAction<CodeList[]>>;
  setCodeOId:any;
}

const MultiChoiceComponent: React.FC<Props> = ({ index, questions, setQuestions, codeList, setCodeList,setCodeOId }) => {

  const handleOptionChange = (optionIndex: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const newCodeList = [...codeList];
    const question = questions.find(q => q.OID === index);
    if (question) {
      const codeListIndex = newCodeList.findIndex(cl => cl.OID === question.CodeListRef?.CodeListOID);
      if (codeListIndex !== -1) {
        const updatedItems = newCodeList[codeListIndex].CodeListItem.map((item, idx) =>
          idx === optionIndex
            ? { ...item, Decode: { TranslatedText: { ...item.Decode.TranslatedText, text: e.target.value } } }
            : item
        );
        newCodeList[codeListIndex].CodeListItem = updatedItems;
        setCodeList(newCodeList);
              // Track changes in the changedCodeListOIDs set
              setCodeOId(prev => {
                const newSet = new Set(prev);
                newSet.add(newCodeList[codeListIndex].OID);
                return newSet;
            });
      }
    }    
  };

  const addOption = () => {
    const newCodeList = [...codeList];
    const question = questions.find(q => q.OID === index);
    if (question) {
      const codeListIndex = newCodeList.findIndex(cl => cl.OID === question.CodeListRef?.CodeListOID);

      if (codeListIndex !== -1) {
        const currentOptions = newCodeList[codeListIndex].CodeListItem || [];
        const newOption = `Option ${currentOptions.length + 1}`;

        newCodeList[codeListIndex].CodeListItem = [
          ...currentOptions,
          {
            Decode: {
              TranslatedText: {
                lang: 'en',
                text: newOption,
              },
            },
            CodedValue: (currentOptions.length + 1).toString(),
            IsEnabled: false
          }
        ];

        setCodeList(newCodeList);
      }
    }
  };

  const removeOption = (optionIndex: number) => {
    const newCodeList = [...codeList];
    const question = questions.find(q => q.OID === index);
    if (question) {
      const codeListIndex = newCodeList.findIndex(cl => cl.OID === question.CodeListRef?.CodeListOID);

      if (codeListIndex !== -1) {
        const updatedItems = newCodeList[codeListIndex].CodeListItem.filter((_, idx) => idx !== optionIndex);
        newCodeList[codeListIndex].CodeListItem = updatedItems;
        setCodeList(newCodeList);
      }
    }
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1', marginLeft: "0px" }}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={12}>
          <FormControl>
            {/* Render options from codeList */}
            {codeList.find(cl => cl.OID === questions.find(q => q.OID === index)?.CodeListRef?.CodeListOID)?.CodeListItem?.map((option: CodeListItem, optionIndex: number) => (
              <Box key={optionIndex} display="flex" alignItems="center">
                <FormControlLabel
                  control={<Checkbox />}
                  label={
                    <>
                      <TextField
                        label={`Option ${optionIndex + 1}`}
                        variant="filled"
                        size="small"
                        value={option.Decode.TranslatedText.text} // Controlled component
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOptionChange(optionIndex, e)}
                      />
                      <IconButton color="secondary" onClick={() => removeOption(optionIndex)}><CloseIcon /> </IconButton>
                    </>
                  }
                />
              </Box>
            ))}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <Button className="primaryTonalButton" size="small" variant="contained" color="primary" onClick={addOption}>
            <AddIcon /> Add Option
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MultiChoiceComponent;
