import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Divider, Grid, TextField, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomDialog from "../pages/Dialog";
import { addCategory, getCategoryMaster } from "../services/CategoryService";

interface CategoryProps {
    open?: any;         // Assuming open is a boolean
    onClose?: () => void; // Assuming onClose is a function with no arguments and no return value
    setRows?: any;
}

const AddCategory: React.FC<CategoryProps> = ({ open, onClose, setRows }) => {
    console.log("hii", open)
    const [openDialog, setOpenDialog] = React.useState<boolean>(open);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState<boolean>(open);

    const [createSuccess, setCreateSuccess] = React.useState<any>(false);
    const [error, setError] = React.useState<any>(false);

    const [selectedIssue, setSelectedIssue] = React.useState<string | null>(null);
    const [category, setCategory] = React.useState<string>('');
    const [expectedAnswer, setExpectedAnswer] = React.useState<string>('');
    const [optionInputs, setOptionInputs] = React.useState(['']); // State to store input values for all options
    const [tradeNames, setTradeNames] = React.useState<any>([]);
    const [selectedTradeItem, setSelectedTradeItem] = React.useState<any>('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
    const { accessToken } = useOidcAccessToken();
    const [jiraContent, setJiraContent] = React.useState<any>({
        // customerUuid:'e43fcc67-5b56-4bba-b07f-d717413f8239',
        name: '',


    });
    const [questionError, setQuestionError] = React.useState(false);
    const [tradeError, setTradeError] = React.useState(false);

    // Handle change in the current option input field
    const handleInputChange = (index) => (event) => {
        const newInputs = [...optionInputs];
        newInputs[index] = event.target.value;
        setOptionInputs(newInputs);
        console.log(optionInputs)
    };

    // Handle adding a new option
    const handleAddOption = () => {
        setOptionInputs([...optionInputs, '']); // Add a new empty string for the new TextField
        console.log(optionInputs)

    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
        handleClose();
    }

    React.useEffect(() => {
        // handleTradeNames();
        console.log(open)
        setOpenDialog(open);
    }, [open]);

    const handleClose = async () => {
        const res = await getCategoryMaster(accessToken);
        setOpenDialog(false);
        setRows(res.data);
        onClose?.();
    };

    const handleAddCategory = (event) => {
        setCategory(event.target.value);
        console.log(category, optionInputs)

    };
    const handleFormSubmit = () => {
        const response = {
            name: category
        }
        let isValid = true;
        if (!category.trim()) {
            setQuestionError(true);
            isValid = false;
        } else {
            setQuestionError(false);
        }
        if (!isValid) return;

        addCategory(response, accessToken).then((res) => {
            setOpenConfirmDialog(true);
            console.log(res);
            if (res.ok === true && res.status === 200 || res.status === 201) {
                setCreateSuccess(true);
                setError(false);

            }
            else{
                setCreateSuccess(false);
                setError(true);

            }
        })

    }

    // const handleFormSubmit = () => {
    //     let isValid = true;
    //     if (selectedIssue === 'Add Question' && !selectedTradeItem) {
    //         setTradeError(true);
    //         isValid = false;
    //     } else {
    //         setTradeError(false);
    //     }

    //     // Validate question
    //     if (!question.trim()) {
    //         setQuestionError(true);
    //         isValid = false;
    //     } else {
    //         setQuestionError(false);
    //     }

    //     // Prevent API call if form is invalid
    //     if (!isValid) return;
    //     console.log("Question:", question);
    //     console.log("Expected Answer:", expectedAnswer);

    //     let jiraContent = {}; // Initialize the variable to store jira content

    //     if (selectedIssue === 'Add Question') {
    //         // Update jiraContent if the issue is 'Add Question'
    //         jiraContent = {
    //             type: selectedIssue,
    //             questions: question,
    //             answers: optionInputs,
    //             tradeName: selectedTradeItem
    //         };
    //     } else {
    //         console.log('hiii');
    //         // Update jiraContent for other issue types
    //         jiraContent = {
    //             type: selectedIssue,
    //             questions: question,
    //             answers: [], // Empty array for answers
    //             tradeName: ''
    //         };
    //     }
    //     // Log the constructed jiraContent
    //     console.log("Final jiraContent:", jiraContent);
    //     // Pass jiraContent to the backend via the API
    //     addJiraContent(jiraContent, accessToken)
    //     .then((res) => {
    //         // if(res.ok)
    //         console.log(res);
    //         setOpenConfirmDialog(true);  // Move this inside the `then` block
    //         setCreateSuccess(true);
    //         setError(false)
    //     })
    //     .catch((error) => {
    //         console.error("Error adding Jira content:", error);  // Optional: handle errors
    //         setCreateSuccess(false);
    //         setError(true)
    //     });
    //     // Add logic to handle form submission
    //     setQuestion('');
    //     setExpectedAnswer('');
    //     setSelectedIssue(null);
    //     setOptionInputs([])
    // };
    const GoBack = () => {
        // console.log("Question:", question);
        // console.log("Expected Answer:", expectedAnswer);
        // const jiraContent={
        //     type:selectedIssue,
        //     questions:question,
        //     answers:optionInputs
        // }
        // console.log(jiraContent);
        // addJiraContent(jiraContent,accessToken).then((res)=>console.log(res))
        // Add logic to handle form submission
        // setQuestion('');
        // setExpectedAnswer('');
        // setSelectedIssue(null);
        // setOptionInputs([])
    };
    // const handleAction = (issueName: string) => {
    //     const handler = issueHandlers[issueName];
    //     if (handler) {
    //         handler();
    //     } else {
    //         console.error('No handler found for:', issueName);
    //     }
    // };
    const handleSelectChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        let values = event.target.value || '';
        setSelectedTradeItem(values);
    }

    //     return (
    //         <div>
    //             <TextField
    //                 fullWidth
    //                 label={'Category Name'}
    //                 value={category}
    //                 onChange={handleAddCategory}
    //                 margin="normal"
    //                 size="small"
    //                 placeholder={'Enter your category name'}
    //                 error={questionError} // Show error if validation fails
    //                 helperText={questionError ? "Question is required" : ""} // Show error message
    //             />
    //             <Box
    //                 display="flex"
    //                 justifyContent="right"
    //                 alignItems="right"
    //                 sx={{ paddingBottom: 2, py: 1 }}
    //             >
    //                 <Button
    //                     size="small"
    //                     variant="contained"
    //                     color="primary"
    //                     onClick={GoBack}
    //                     sx={{ marginTop: '16px', marginRight: '9px' }}
    //                 >
    //                     Go Back
    //                 </Button>
    //                 <Button
    //                     size="small"
    //                     variant="contained"
    //                     color="primary"
    //                     onClick={handleFormSubmit}
    //                     sx={{ marginTop: '16px' }}
    //                 >
    //                     Submit
    //                 </Button>
    //             </Box>
    //         </div>
    //     );
    // };

    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={openDialog}
                onClose={handleClose}
                maxWidth={maxWidth}
                fullWidth
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <span>Add Category</span>
                        {/* <IconButton sx={{ color: 'red' }} edge="end" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton> */}
                    </Box>
                </DialogTitle>
                <Divider />
                <DialogContent className='issueContent'>
                    <DialogContentText>
                        {/* {selectedIssue ? renderForm() : ( */}
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    label={'Category Name'}
                                    value={category}
                                    onChange={handleAddCategory}
                                    margin="normal"
                                    size="small"
                                    placeholder={'Enter your category name'}
                                    error={questionError} // Show error if validation fails
                                    helperText={questionError ? "Category name is required" : ""} // Show error message
                                />
                                <Box
                                    display="flex"
                                    justifyContent="right"
                                    alignItems="right"
                                    sx={{ paddingBottom: 2, py: 1 }}
                                >
                                    {/* <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        onClick={GoBack}
                                        sx={{ marginTop: '16px', marginRight: '9px' }}
                                    >
                                        Go Back
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        onClick={handleFormSubmit}
                                        sx={{ marginTop: '16px' }}
                                    >
                                        Submit
                                    </Button> */}
                                </Box>
                            </Grid>
                        </Grid>
                        {/* )} */}
                    </DialogContentText>
                </DialogContent>
                {!selectedIssue && (<DialogActions>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleFormSubmit}
                    // sx={{ marginTop: '16px' }}
                    >
                        Submit
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleClose} autoFocus>
                        Close
                    </Button>
                </DialogActions>
                )}
            </Dialog>
            <CustomDialog
                open={openConfirmDialog}
                onClose={handleCloseConfirmDialog}
                success={createSuccess}
                error={error}
                Content={createSuccess ? "Category create Successfully" : "Failed"}
            />
        </React.Fragment>
    );
};

export default AddCategory;